.Profile {
    &__generalInput {
        padding-bottom: 5px;
    }
    &__notifications {
        padding: 15px 0 15px 10px;

        .MuiTypography-body1 {
            font-weight: initial !important;
        }

        &-digestFrequency {
            margin-right: 0 !important;
        }
        &-frequency {
            width: 100px;
        }
    }
}
