.Dashboard {
    &__header {
        align-items: center;
        display: flex;
        gap: 0.5em;
        justify-content: space-between;
        margin: 0 1rem 1rem;
        padding-top: 1em;

        a:hover {
            color: var(--primary-contrastText);
        }

        .MuiButton-label {
            text-transform: initial;
        }

        .MuiSvgIcon-root {
            padding-right: 0.25em;
        }

        .MuiTypography-h3 {
            font-weight: 600;
        }
        .MuiButton-startIcon {
            svg {
                color: var(--primary-contrastText);
            }
        }
    }

    &__main {
        height: calc(100vh - 188px - 5rem);
        position: relative;

        &-loading {
            left: 50%;
            margin: -60px 0 0 -20px;
            position: absolute;
            top: 50%;
        }
    }

    &__data {
        align-items: flex-start;
        height: calc(100vh - 188px - 6rem);
        margin-top: 1rem;

        &-requests {
            height: 100%;
            // padding: 1em;
        }

        .MuiPaper-root {
            height: fit-content;
        }
    }

    .MuiButton-colorPrimary:hover {
        color: var(--primary-light);
    }
}
