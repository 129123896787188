nav {
    .MuiButtonBase-root {
        color: var(--primary-main);
        font-size: 1.186em;
        margin-right: 2.5em;
        max-width: initial;
        min-width: initial;

        &:hover {
            color: var(--primary-main);
        }
    }

    .MuiTabs-indicator {
        top: 2.25em;
    }

    .MuiTab-root {
        padding: 6px;
    }

    .MuiTabs-root {
        width: 100%;

        .MuiTabs-flexContainer {
            .Nav__approver {
                margin-right: 0;
                position: absolute;
                right: 120px;
                top: 0;
            }

            .Nav__admin {
                margin-right: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}
