// stylelint-disable
@import './mixins.scss';

/* montserrat-300 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/montserrat-v18-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/montserrat-v18-latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/montserrat-v18-latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/montserrat-v18-latin-300.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/montserrat-v18-latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/montserrat-v18-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-300italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/montserrat-v18-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/montserrat-v18-latin-300italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/montserrat-v18-latin-300italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/montserrat-v18-latin-300italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/montserrat-v18-latin-300italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/montserrat-v18-latin-300italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/montserrat-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/montserrat-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/montserrat-v18-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/montserrat-v18-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/montserrat-v18-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/montserrat-v18-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/montserrat-v18-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/montserrat-v18-latin-italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/montserrat-v18-latin-italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/montserrat-v18-latin-italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/montserrat-v18-latin-italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/montserrat-v18-latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/montserrat-v18-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/montserrat-v18-latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/montserrat-v18-latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/montserrat-v18-latin-600.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/montserrat-v18-latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/montserrat-v18-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/montserrat-v18-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/montserrat-v18-latin-600italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/montserrat-v18-latin-600italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/montserrat-v18-latin-600italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/montserrat-v18-latin-600italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/montserrat-v18-latin-600italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/montserrat-v18-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/montserrat-v18-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/montserrat-v18-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/montserrat-v18-latin-700.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/montserrat-v18-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/montserrat-v18-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/montserrat-v18-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/montserrat-v18-latin-700italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/montserrat-v18-latin-700italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/montserrat-v18-latin-700italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/montserrat-v18-latin-700italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/montserrat-v18-latin-700italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

$default-font: 'Montserrat', 'Trebuchet', 'Helvetica', sans-serif;

$fontstack-body: $default-font;
$fontstack-headings: $default-font;

$fontweight-light: 300;
$fontweight-normal: 400;
$fontweight-bold: 700;

$fontsize-xs: 0.625em;
$fontsize-small: 0.75em;
$fontsize-normal: 1em;
$fontsize-large: 1.286em;
$fontsize-xl: 1.429em;
$fontsize-xxl: 1.714em;

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--primary-main);
}

h1 {
    @include fontsize($fontsize-xxl);
}

h2 {
    @include fontsize($fontsize-xl);
}

h3 {
    @include fontsize($fontsize-large);
}

h4 {
    @include fontsize($fontsize-normal);
}

h5 {
    @include fontsize($fontsize-small);
}

h6 {
    @include fontsize($fontsize-xs);
}
