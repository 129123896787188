@import 'assets/sass/variables';

.TravelApproval {
    flex-grow: 1;

    &__adornment {
        font-weight: 600;
    }

    &__header {
        align-items: center;
        display: grid;
        grid: 'auto-flow' / 0.45fr 1fr 2fr;
        justify-items: start;

        &-chips {
            justify-self: start;
            .MuiChip-root {
                margin-right: 5px;
                background-color: #f4f4f4;
            }
        }

        &-buttons {
            justify-self: end;
            padding: 1.05em;

            .MuiButton-containedPrimary {
                margin-right: 0.5em;
            }
        }
        &-selected {
            opacity: 1;
        }
        &-pending,
        &-history {
            opacity: 0.5;
        }
    }

    &__title {
        padding: 1.05em;
    }

    &__data {
        height: calc(100vh - 188px - 5rem);
    }

    
    .MuiTypography-h5 {
        font-size: .875em;
    }

    .MuiDataGrid-root {
        border: 0;

        .MuiDataGrid-columnHeaderTitle {
            font-weight: 700;
        }
        .MuiDataGrid-iconSeparator {
            display: none;
        }
    }

    .MuiDataGrid-columnHeaderWrapper {
        background-color: $cloudy-background;
    }

    .MuiDataGrid-row {
        &:hover {
            background-color: var(--background-paper) !important;
        }
    }

    .MuiTypography-h3 {
        font-weight: 600;
    }

    a {
        font-weight: 700;
        text-transform: capitalize;
    }

    a:hover {
        color: var(--primary-main);
    }
}
