.BaseDialog {
    .MuiDialog-paperWidthSm {
        min-width: 600px;
    }

    &__title {
        &.MuiDialogTitle-root {
            padding-bottom: 0;
            h2 {
                font-weight: bold;
            }
        }
    }

    &__content {
        .MuiDialogContentText-root {
            color: var(--text-primary);
            font-weight: bold;
        }
    }

    .MuiDialogActions-root {
        padding: 0 25px 15px 8px;
    }
}
