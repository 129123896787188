.Login {
    background-color: var(--text-primary);
    display: flex;
    height: 100vh;

    &__container {
        display: flex;
        flex-direction: column;
        height: 400px;
        margin: auto;
        padding: 25px 50px;
        width: 510px;

        h1 {
            font-size: 3em;
        }

        .MuiButton-root {
            margin: auto;
            max-width: 90px;
        }

        &-icons {
            color: var(--primary-main);
            margin: auto;
            margin-bottom: 0.5em;

            svg {
                height: 2.25em;
                width: 2.25em;
            }
        }
    }
}
