.TravelApprovalDetails {
    .MuiBreadcrumbs-root {
        margin-bottom: 1.25em;
        margin-top: 0.5em;

        p {
            font-weight: 600;
        }
    }

    &__header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;
    }

    &__loading {
        left: 50%;
        margin: -20px 0 0 -20px;
        position: absolute;
        top: 50%;
    }

    &__main {
        display: grid;
        gap: 1em;
        grid-template-areas: 'details actions';
        grid-template-columns: 2fr 1fr;

        &-details {
            display: flex;
            flex-direction: column;
            gap: 1em;
            grid-area: details;

            .FlexLayout__flexStart {
                margin-bottom: 1em;
            }

            .MuiFormControl-fullWidth {
                width: auto;
            }

            .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child {
                font-family: 'Montserrat', 'Trebuchet', 'Helvetica', sans-serif;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.43;
                padding: 0 0 0 6px;
                width: fit-content;
            }
        }

        &-actions {
            display: flex;
            flex-direction: column;
            gap: 1em;
            grid-area: actions;

            .ApproveButton {
                background-color: var(--success-main);

                .MuiButton-startIcon {
                    svg {
                        color: var(--primary-contrastText);
                    }
                }
            }

            .DenyButton {
                border-color: var(--error-main);
                color: var(--error-main);
            }
        }

        &-historyContainer {
            overflow: auto;
            min-height: 200px;
            max-height: 150px;
        }

        &-history {
            padding-bottom: 0.15em;
            padding-top: 0.15em;
        }

        .ApproverEdit {
            align-items: center;
            display: flex;
            justify-content: flex-end;
        }

        .MuiPaper-root {
            padding: 1em;
        }

        .MuiTypography-body1 {
            font-weight: bold;
        }

        .MuiTypography-h5 {
            font-size: .875em;
        }

        .MuiDivider-root {
            background-color: var(--primary-main);
            margin: 0.75em 0;
        }
    }

    &__grid {
        display: grid;
        gap: 1em;
        grid: 'left middle right' / 1fr 1fr 1fr;

        &-left {
            grid-area: left;
        }

        &-middle {
            grid-area: middle;
        }

        &-right {
            grid-area: right;
        }

        &-left,
        &-middle,
        &-right {
            div {
                margin-bottom: 1em;
            }

            .MuiTypography-body1 {
                font-weight: bold;
            }
        }
    }

    .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        padding-right: inherit;
    }

    &__fullWidth {
        margin-bottom: 1em;
    }

    &__dialog-title {
        &.MuiDialogTitle-root {
            padding-bottom: 0;
            h2 {
                font-weight: bold;
            }
        }
    }
    &__dialog-content {
        .MuiDialogContentText-root {
            color: var(--text-primary);
            font-weight: bold;
        }
    }
}
