@import 'assets/sass/variables';

.TravelApproval {
    flex-grow: 1;

    &__adornment {
        font-weight: 600;
    }

    &__header {
        align-items: center;
        display: grid;
        grid: 'auto-flow' / 1fr 1fr;
        justify-items: start;

        &-buttons {
            justify-self: end;
            padding: 1.05em;

            .MuiButton-containedPrimary {
                margin-right: 0.5em;
            }
        }
        &-selected {
            opacity: 1;
        }
        &-pending,
        &-history {
            opacity: 0.5;
        }
    }

    &__title {
        padding: 1.05em;
    }

    &__data {
        height: calc(100vh - 188px - 5rem);
    }

    .MuiDataGrid-root {
        border: 0;

        .MuiDataGrid-columnHeaderTitle {
            font-weight: 700;
        }
        .MuiDataGrid-iconSeparator {
            display: none;
        }
    }

    .MuiDataGrid-columnHeaderWrapper {
        background-color: $cloudy-background;
    }

    .MuiDataGrid-row {
        &:hover {
            background-color: var(--background-paper) !important;
        }
    }

    .MuiTypography-h3 {
        font-weight: 600;
    }

    a {
        font-weight: 700;
        text-transform: capitalize;
    }

    a:hover {
        color: var(--primary-main);
    }
}
