@import 'assets/sass/variables';

.TravelDataGrid {
    flex-grow: 1;

    &__adornment {
        font-weight: 600;
    }

    .MuiDataGrid-root {
        border: 0;

        .MuiDataGrid-columnHeaderTitle {
            font-weight: 700;
        }
        .MuiDataGrid-iconSeparator {
            display: none;
        }
    }

    .MuiDataGrid-columnHeaderWrapper {
        background-color: $cloudy-background;
    }

    .MuiDataGrid-row {
        &:hover {
            background-color: var(--background-paper) !important;
        }
    }

    a {
        font-weight: 700;
        text-transform: capitalize;
    }

    a:hover {
        color: var(--primary-main);
    }
}

.RequestDataGrid {
    display: flex;
    height: 100%;
}
