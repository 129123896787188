@import 'assets/sass';

.TravelRequest {
    &__loading {
        left: 50%;
        margin: -20px 0 0 -20px;
        position: absolute;
        top: 50%;
    }

    &__actions {
        display: flex;
        gap: 0.5em;
        justify-content: flex-end;
        margin-top: 1em;
    }

    &__review {
        &-title {
            font-size: 1.1rem !important;
            font-weight: normal !important;
            text-align: center;
        }
    }

    .MuiTypography-body1 {
        font-weight: 600;
    }

    &__form {
        &-select-field {
            .MuiSelect-select:focus {
                background: none;
            }
        }

        &-select-label {
            background: var(--background-paper);
            padding: 0 0.5em !important;
        }

        &-noHelperText {
            .MuiFormLabel-root,
            .MuiInputBase-root {
                margin-top: 1em;
            }
        }
    }

    &__details {
        .MuiBreadcrumbs-root {
            margin-bottom: 1.25em;
            margin-top: 0.5em;

            a {
                font-weight: normal;
            }

            p {
                font-weight: 600;
            }
        }

        &-header {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
        }

        &-actions {
            column-gap: 0.5em;
            display: flex;
        }

        &-main {
            align-items: start;
            column-gap: 1em;
            display: grid;
            grid: auto-flow dense / 2fr 1fr;

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    margin: 0 0 0.75em;
                    padding: 0;
                }
            }

            &-status {
                h3 {
                    margin-bottom: 0.25em;
                }

                blockquote {
                    border-left: 2px solid var(--black);
                    margin: 0 0 0 0.25em;
                    padding: 0 0 0 0.5em;
                }

                .Status {
                    &__denied {
                        .MuiTypography-body1 {
                            color: var(--error-main);
                        }

                        blockquote {
                            border-color: var(--error-main);
                        }
                    }
                }
                &-bottom {
                    margin-top: 0.5em;
                    .Title {
                        display: flex;
                        justify-content: space-between;
                    }
                    .DateTime {
                        color: var(--primary-light);
                    }
                    .TripNumber {
                        font-weight: 200;
                    }
                    .FieldChange {
                        display: flex;
                        background-color: var(--background-default);
                        border: 1px solid darken(#f7f7f7, 10%);

                        border-radius: 5px;
                        margin-top: 0.5em;
                        padding: 0.5em;
                    }
                    .ValueText {
                        font-size: 0.8rem !important;
                        margin: auto;
                        width: 100%;
                        word-wrap: break-word;
                        text-align: center;
                        &-new {
                            @extend .ValueText;
                            font-style: italic;
                        }
                    }
                    .HistoryItem {
                        margin-top: 1em;
                    }
                }
            }
        }

        &-edit {
            justify-self: end;
        }

        .MuiTableCell-head {
            width: 150px;
        }
    }

    .FlexLayout,
    .FlexLayout__flexStart {
        margin-bottom: 1.5em;
    }

    .MuiDivider-root {
        background-color: var(--primary-main);
        margin: 1em 0;
    }

    .MuiPaper-root {
        padding: 1em;
    }

    .MuiTypography-body2 {
        font-size: 1em;
    }
    .MuiTypography-h5 {
        font-size: .875em;
    }
}
