@import 'assets/sass';

html {
    height: 100%;
}

body {
    background-color: var(--background-default);
    font-family: var(--font-family);
    font-size: var(--font-size);
    margin: 0;
    padding: 0;
}

a {
    color: var(--primary-main);
    text-decoration: none;

    &:hover {
        color: var(--action-hover);
    }

    &:focus,
    &:active {
        color: var(--action-focus);
    }

    &:disabled {
        color: var(--action-disabled);
    }
}

.App {
    &__header {
        h1 {
            color: var(--primary-contrastText);
            padding: 0.5em 0;
        }
        .MuiButton-label {
            display: flex;
            flex-direction: column;
            font-weight: 100;
        }
        .MuiToolbar-root {
            align-items: center;
            color: var(--primary-contrastText);
            display: flex;
            justify-content: space-between;
        }
        .MuiSvgIcon-root {
            color: var(--primary-contrastText) !important;
            font-size: 30px;
        }
        .MuiButton-root {
            background: transparent;
            color: var(--primary-contrastText);
        }
    }

    &__main {
        padding-bottom: 1em;
        padding-top: 1.5em;
    }

    .FlexLayout {
        align-items: flex-start;
        column-gap: 0.5em;
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__quarter {
            width: 25%;
        }

        &__third {
            width: 33%;
        }

        &__half {
            width: 50%;
        }

        &__twoThirds {
            width: 67%;
        }

        &__threeQuarters {
            margin-right: 1.5em;
            width: 75%;
        }

        &__flexStart {
            @extend .FlexLayout;

            justify-content: flex-start;
        }

        &__spaceEvenly {
            @extend .FlexLayout;

            justify-content: space-evenly;
        }

        &__spaceAround {
            @extend .FlexLayout;

            justify-content: space-around;
        }

        &__column {
            @extend .FlexLayout;

            flex-direction: column;
        }
    }

    .TravelDestination {
        white-space: pre;
    }

    .MuiToolbar-regular {
        min-height: 0;
    }

    .MuiSvgIcon-root {
        color: var(--primary-main);
    }
    .MuiAlert-icon {
        svg {
            color: var(--primary-contrastText);
        }
    }
}
